import type { FormFilter, FormFilterName } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaChip } from "@/components/general/GaChip";
import { assertIsDefined } from "@utils/assertion";
import type { DeepReadonly } from "vue";

function getFilterLabel(filter: DeepReadonly<FormFilter>, activeValue: string): string {
  const option = filter.options.find((option) => option.value === activeValue);
  assertIsDefined(option);
  return option.shortLabel !== null && option.shortLabel !== "" ? option.shortLabel : option.label;
}

export function FilterChip(props: { filter: DeepReadonly<FormFilter>; resetFilter: (filterName: FormFilterName) => void }): GaVueComponent {
  const filter = props.filter;
  switch (filter.type) {
    case "MULTICHECKBOX":
      if (filter.activeValues.length > 0) {
        return (
          <GaChip active={true} type="removable" removeHandler={() => props.resetFilter(filter.identifier)}>
            {filter.activeValues.map((value) => getFilterLabel(filter, value)).join(", ")}
          </GaChip>
        );
      }
      break;
    case "CHECKBOX":
      if (filter.activeValues.at(0) === "true") {
        return (
          <GaChip active={true} type="removable" removeHandler={() => props.resetFilter(filter.identifier)}>
            {filter.options.at(0)?.label}
          </GaChip>
        );
      }
      break;
    case "MULTISELECT":
      if (filter.activeValues.length > 0) {
        return (
          <GaChip active={true} type="removable" removeHandler={() => props.resetFilter(filter.identifier)}>
            {filter.activeValues.map((value) => getFilterLabel(filter, value)).join(", ")}
          </GaChip>
        );
      }
      break;
    case "RANGE": {
      const lowerOption = filter.options.at(0);
      const higherOption = filter.options.at(1);
      assertIsDefined(lowerOption);
      assertIsDefined(higherOption);
      const firstLimit = filter.activeValues.at(0);
      const secondLimit = filter.activeValues.at(1);
      const selectedLowerLimit = firstLimit ?? lowerOption.value;
      const selectedUpperLimit = secondLimit ?? higherOption.value;
      if (Number(selectedLowerLimit) > Number(lowerOption.value) || Number(selectedUpperLimit) > Number(higherOption.value)) {
        return (
          <GaChip active={true} type="removable" removeHandler={() => props.resetFilter(filter.identifier)}>
            {filter.label}: {selectedLowerLimit}
            {selectedUpperLimit !== selectedLowerLimit ? <span>&ndash;{selectedUpperLimit}</span> : null}
          </GaChip>
        );
      }
      break;
    }
    case "RANGE_MIN": {
      const current = filter.activeValues.at(0);
      const min = filter.options.at(0);
      const max = filter.options.at(1);
      if (current !== undefined && min !== undefined && Number(current) > Number(min.value)) {
        return (
          <GaChip active={true} type="removable" removeHandler={() => props.resetFilter(filter.identifier)}>
            {filter.label}: {current}
            {max !== undefined && Number(current) < Number(max.value) ? "+" : null}
          </GaChip>
        );
      }
    }
  }
  return <></>;
}
