import { registerModule } from "@/Registry";
import { getCsrfToken } from "@utils/csrfUtils";

type TitleElementTriple = {
  container: Element;
  input: HTMLInputElement;
  h1: Element;
};

async function handleFetchErrors(response: Response): Promise<void> {
  if (!response.ok) {
    throw Error(await response.text());
  }
}

function showError(): void {
  // @ts-expect-error (old javascript library)
  window.noty({
    text: "Error",
    timeout: 1000,
    theme: "relax",
    layout: "center",
    type: "error",
  });
}

function editTitle(titleElements: TitleElementTriple): void {
  titleElements.container.classList.add("mylist-header__heading--editing");
  titleElements.input.value = titleElements.h1.textContent?.trim() ?? "";
  titleElements.input.focus();
}

async function saveTitle(titleElements: TitleElementTriple, url: string): Promise<void> {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-XSRF-Token": await getCsrfToken(),
      },
      body: "title=" + encodeURIComponent(titleElements.input.value),
    });
    void handleFetchErrors(response);
    titleElements.h1.textContent = titleElements.input.value;
  } catch {
    showError();
  } finally {
    titleElements.container.classList.remove("mylist-header__heading--editing");
  }
}

async function deleteList(url: string): Promise<void> {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "X-XSRF-Token": await getCsrfToken(),
      },
    });
    void handleFetchErrors(response);
    window.location.replace(await response.text());
  } catch {
    showError();
  }
}

async function removeItem(trashBtn: HTMLElement, url: string): Promise<void> {
  const dataId = trashBtn.dataset["gaDataid"];
  if (dataId !== undefined) {
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "X-XSRF-Token": await getCsrfToken(),
        },
        body: "dataId=" + -1 * parseInt(dataId), // A negative dataId signals the removal of a title in our REST API, thus (*-1)
      });
      void handleFetchErrors(response);
      if (response.ok) {
        trashBtn.closest("[data-ga-deletable-card]")?.remove();
      }
    } catch {
      showError();
    }
  }
}

function getTitleElements(scope: HTMLElement): TitleElementTriple {
  const container = scope.querySelector("[data-ga-mylist-title]");
  const input = scope.querySelector<HTMLInputElement>("[data-ga-mylist-title-edit-view]");
  const h1 = scope.querySelector("[data-ga-mylist-title-view]");
  if (container === null || input === null || h1 === null) {
    throw Error("Missing DOM elements for title edit");
  }
  return {
    container,
    input,
    h1,
  };
}

function init(scope: Element): void {
  if (!(scope instanceof HTMLElement)) {
    return;
  }
  const apiUrl = scope.dataset.gaMylistApiUrl;
  if (apiUrl === undefined) {
    return;
  }
  const titleElements = getTitleElements(scope);

  scope.querySelector("[data-ga-mylist-title-modify]")?.addEventListener("click", () => editTitle(titleElements));
  scope.querySelector("[data-ga-mylist-title-edit-save]")?.addEventListener("click", () => saveTitle(titleElements, apiUrl));
  scope.querySelector("[data-ga-mylist-delete]")?.addEventListener("click", () => deleteList(apiUrl));
  titleElements.input.addEventListener("keydown", (e) => {
    if (e.code === "Enter") {
      void saveTitle(titleElements, apiUrl);
    }
  });
  scope.addEventListener("click", (e) => {
    const trashBtn = e.target instanceof HTMLElement ? e.target.closest<HTMLElement>("[data-ga-delete-action]") : null;
    if (trashBtn !== null) {
      void removeItem(trashBtn, apiUrl);
    }
  });
}

registerModule("[data-ga-mylist-api-url]", init);
