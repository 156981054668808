/*
 * Plugin for the channel-insight component, which handles the on-the-go reloading of channel channel insights
 */
import { assert, ensureNonNull } from "@utils/assertion";
import "./_channel-insight.scss";
import { registerModule } from "@/Registry";

export function initChannelInsight(element: Element, fetchChannelInsights: (channelId: number) => Promise<string | Error>): void {
  const contentPane = element.querySelector("[data-ga-channel-insight-content]");
  assert(contentPane !== null);

  element.querySelectorAll("[data-ga-channel-insight-channel-id]").forEach((chip) =>
    chip.addEventListener("activated.ga.chip", async (e) => {
      const target = e.currentTarget;
      assert(target instanceof HTMLElement);

      const channelId = parseInt(ensureNonNull(target.getAttribute("data-ga-channel-insight-channel-id")));
      contentPane.classList.add("loading");
      const response = await fetchChannelInsights(channelId);
      if (response instanceof Error) {
        contentPane.innerHTML = "Unable to fetch content from endpoint";
      } else {
        contentPane.innerHTML = response;
      }
      contentPane.classList.remove("loading");
    }),
  );
}

registerModule("[data-ga-channel-insight]", (e) =>
  initChannelInsight(e, async (channelId) => {
    const response = await fetch("/channels/" + channelId + "/insights");
    if (!response.ok) {
      return new Error(response.statusText);
    }
    return response.text();
  }),
);
