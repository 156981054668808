import { defineComponent, onMounted, type PropType, ref } from "vue";
import Tooltip from "bootstrap/js/dist/tooltip";
import { assert } from "@utils/assertion";

export const GaTooltip = defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    placement: {
      type: String as PropType<Tooltip.PopoverPlacement>,
      required: false,
    },
    htmlEnabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { slots }) {
    const wrapperEl = ref<HTMLElement | null>(null);
    onMounted(() => {
      assert(wrapperEl.value !== null, "wrapperEl not expected be null");
      new Tooltip(wrapperEl.value, {
        title: props.title,
        html: props.htmlEnabled,
        placement: props.placement ?? "top",
      });
    });
    return () => <span ref={wrapperEl}>{slots.default?.()}</span>;
  },
});
