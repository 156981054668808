import type { GaVueComponent } from "@/common/vueUtils";
import type { FormFilter, FormFilterName } from "@newgenerated/shared/schema";
import { GaChip } from "@/components/general/GaChip";
import { assertIsDefined } from "@utils/assertion";
import type { DeepReadonly } from "vue";
import { GaFormFieldMultiSelect } from "@/components/form/GaFormFieldMultiSelect";
import type { FormFilterExtended } from "@/components/search/fullSearchStoreTypes";

function Header(props: { headerId: string; collapseId: string; filter: DeepReadonly<FormFilter>; onClickHeader: (filterName: FormFilterName) => void; isOpen: boolean }): GaVueComponent {
  return (
    <h5 class="filtering-dropdown__header" id={props.headerId} onClick={() => props.onClickHeader(props.filter.identifier)}>
      <button class="filtering-dropdown__header-button" type="button" aria-expanded={props.isOpen} aria-controls={props.collapseId}>
        <span>{props.filter.label}</span>
        <i class="ico-chevron-down" aria-hidden="true"></i>
      </button>
    </h5>
  );
}

function FilterImplementation(props: {
  filter: DeepReadonly<FormFilterExtended>;
  updateFilter: (filterName: FormFilterName, newActiveValues: string[]) => void;
  onMultiSelectSearchTermChange: (formFilterName: FormFilterName, searchTerm: string) => void;
  toggleMultiSelectSearch: (formFilterName: FormFilterName) => void;
}): GaVueComponent {
  const filter = props.filter;
  const filterId = filter.name + "-filter-" + window.crypto.randomUUID().substring(0, 7);
  switch (filter.type) {
    case "MULTICHECKBOX":
      return (
        <ul class="list-unstyled filtering-dropdown__chip-list">
          {filter.options.map((filterOption) => {
            const active = filter.activeValues.includes(filterOption.value);
            return (
              <li>
                <GaChip active={active} type="checkbox" changeHandler={() => props.updateFilter(filter.identifier, active ? filter.activeValues.filter((f) => f !== filterOption.value) : [...filter.activeValues, filterOption.value])}>
                  {filterOption.label}
                </GaChip>
              </li>
            );
          })}
        </ul>
      );
    case "CHECKBOX": {
      const active = filter.activeValues.at(0) === "true";
      return (
        <GaChip active={active} type="checkbox" changeHandler={() => props.updateFilter(filter.identifier, [active ? "false" : "true"])}>
          {filter.options.at(0)?.label}
        </GaChip>
      );
    }
    case "MULTISELECT": {
      assertIsDefined(filter.multiSelectProps);
      return (
        <>
          <label for={filterId} class="visually-hidden">
            {filter.label}
          </label>
          <GaFormFieldMultiSelect
            options={[...filter.multiSelectProps.options]}
            selectedOptions={[...filter.multiSelectProps.selectedOptions]}
            onChange={(selection) =>
              props.updateFilter(
                filter.identifier,
                selection.map((value) => value.value),
              )
            }
            searchTerm={filter.multiSelectProps.searchTerm}
            onSearchTermChange={(term) => props.onMultiSelectSearchTermChange(filter.identifier, term)}
            showSearch={filter.multiSelectProps.showSearch}
            toggleSearch={() => props.toggleMultiSelectSearch(filter.identifier)}
          />
        </>
      );
    }
    case "RANGE": {
      const min = filter.options.at(0);
      const max = filter.options.at(1);
      assertIsDefined(min);
      assertIsDefined(max);
      const firstLimit = filter.activeValues.at(0);
      const secondLimit = filter.activeValues.at(1);
      const selectedLowerLimit = firstLimit ?? min.value;
      const selectedUpperLimit = firstLimit === undefined ? max.value : (secondLimit ?? firstLimit);
      return (
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            aria-label={filter.label + ": Lower limit"}
            value={selectedLowerLimit}
            min={min.value}
            max={max.value}
            onInput={(event) => props.updateFilter(filter.identifier, [(event.target as HTMLInputElement).value, secondLimit ?? max.value])}
          />
          <span class="input-group-text">&ndash;</span>
          <input
            type="number"
            class="form-control"
            aria-label={filter.label + ": Upper limit"}
            value={selectedUpperLimit}
            min={min.value}
            max={max.value}
            onInput={(event) => props.updateFilter(filter.identifier, [firstLimit ?? min.value, (event.target as HTMLInputElement).value])}
          />
        </div>
      );
    }
    case "RANGE_MIN": {
      const activeValue = filter.activeValues.at(0);
      const minValue = filter.options.at(0);
      const maxValue = filter.options.at(1);
      assertIsDefined(activeValue);
      assertIsDefined(maxValue);
      assertIsDefined(minValue);
      const incrementingNumbers = Array.from({ length: Number(Number(maxValue.value) - Number(minValue.value) + 1) }, (_, i) => Number(minValue.value) + i);
      return (
        <>
          <label for={filterId} class="visually-hidden">
            {filter.label}
          </label>
          <select id={filterId} class="form-control form-select">
            {incrementingNumbers.map((index) => (
              <option value={index} selected={index === Number(activeValue)} onSelect={() => props.updateFilter(filter.identifier, [index.toString()])}>
                {index}
                {index < Number(maxValue) ? "+" : ""}
              </option>
            ))}
          </select>
        </>
      );
    }
  }
}

export function Filter(props: {
  filter: DeepReadonly<FormFilterExtended>;
  updateFilter: (filterName: FormFilterName, newActiveValues: string[]) => void;
  toggleFilter: (filterName: FormFilterName) => void;
  onMultiSelectSearchTermChange: (formFilterName: FormFilterName, searchTerm: string) => void;
  toggleMultiSelectSearch: (formFilterName: FormFilterName) => void;
}): GaVueComponent {
  const filter = props.filter;
  const uuid = window.crypto.randomUUID().substring(0, 7);
  const collapseId = filter.name + "filter-collapse" + uuid;
  const headerId = filter.name + "filter-collapse-header" + uuid;
  return (
    <section>
      <Header filter={filter} headerId={headerId} collapseId={collapseId} onClickHeader={props.toggleFilter} isOpen={!props.filter.isCollapsed} />
      <div class={["filtering-dropdown__body", props.filter.isCollapsed ? "collapse" : ""]} id={collapseId}>
        <FilterImplementation filter={filter} updateFilter={props.updateFilter} onMultiSelectSearchTermChange={props.onMultiSelectSearchTermChange} toggleMultiSelectSearch={props.toggleMultiSelectSearch} />
      </div>
    </section>
  );
}
